import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { cancelAllOrders, fetchOrders } from "../services/data-service";
import { OrderResult } from "../models/order"


export default function Orders() {

    const [orders, setOrders] = useState<OrderResult>();
    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const orders = await fetchOrders();
        setOrders(orders);
    }

    const callCancelOrder = async (symbol: string) => {
        const confirmed = window.confirm("Cancel all " + symbol + "Orders ?");

        if (confirmed) {
            await cancelAllOrders(symbol);
            await loadData();
        }
    }


    return (
        <div>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Orders</h1>
                        </div>
                        <div className="col-sm-4">

                        </div>
                        <div className="col-sm-2" onClick={() => { loadData(); }}>
                            <ol className="breadcrumb float-sm-right">
                                <button onClick={() => { loadData(); }} className="btn btn-primary btn-sm bg-gradient-success">
                                    Refresh
                                </button>
                            </ol>
                        </div>
                    </div>
                    <div>
                        <div className="row">
                            <div className="col-12 col-sm-4">
                                <div className="info-box bg-light">
                                    <div className="info-box-content">
                                        <span className="info-box-text text-center text-muted">Profit</span>
                                        <span className="info-box-number text-center text-muted mb-0">{orders?.profitValue.toFixed(2)} $</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4">
                                <div className="info-box bg-light">
                                    <div className="info-box-content">
                                        <span className="info-box-text text-center text-muted">Positions</span>
                                        <span className="info-box-number text-center text-muted mb-0">{orders?.symbolOrders.length}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4">
                                <div className="info-box bg-light">
                                    <div className="info-box-content">
                                        <span className="info-box-text text-center text-muted">Total amount</span>
                                        <span className="info-box-number text-center text-muted mb-0">{orders?.allOrders.reduce((v1, v2) => v1 + v2.amount, 0).toFixed(0)} $</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content">

                <div className="card">
                    <div className="card-body p-0">
                        <table className="table table-striped projects">
                            <thead>
                                <tr>
                                    <th style={{ width: "10%" }}>
                                        Symbol
                                    </th>
                                    <th style={{ width: "10%" }}>
                                        Size
                                    </th>
                                    <th style={{ width: "20%" }}>
                                        Profit
                                    </th>
                                    <th style={{ width: "10%" }} className="text-center">
                                        Position
                                    </th>
                                    <th style={{ width: "10%" }} className="text-center">
                                        Avg Price
                                    </th>
                                    <th style={{ width: "30%" }}>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    orders?.symbolOrders?.map(order => (
                                        <tr>
                                            <td>
                                                <a>
                                                    {order.symbol}
                                                </a>
                                                <br />
                                                <small>
                                                    Orders : {order.count}
                                                </small>
                                            </td>
                                            <td>
                                                {
                                                    order.orders.reduce((v1, v2) => v1 + v2.amount, 0.0).toFixed(0)
                                                }
                                            </td>
                                            <td className="project_progress">
                                                <div className="progress progress-sm">
                                                    <div className={`progress-bar " role="progressbar ` + (order.profitValue > 0 ? 'bg-green' : 'bg-red')} role="progressbar" style={{ width: "57%" }}>
                                                    </div>
                                                </div>
                                                <small>
                                                    {Math.floor(order.profitValue)} $
                                                </small>
                                                <br />
                                                <small>
                                                    {order.profit.toFixed(2)}
                                                </small>
                                            </td>
                                            <td className="project-state">
                                                {Math.floor(order.profitValue)} $
                                            </td>
                                            <td>
                                                {order.avgPrice?.toFixed(2)}
                                            </td>
                                            <td className="project-actions text-right">
                                                <Link to={`/order-details/${order.symbol}`} className="btn btn-primary btn-sm" style={{ marginRight: 10 }}>
                                                    <i className="fas fa-folder">
                                                    </i>
                                                    View
                                                </Link>
                                                <button onClick={() => { callCancelOrder(order.symbol) }} className="btn btn-danger btn-sm">
                                                    <i className="fas fa-trash">
                                                    </i>
                                                    Cancel All
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

            </section>
        </div>
    );
}