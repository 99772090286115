import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { cancelOrder, fetchOrders } from "../services/data-service";
import { OrderResult } from "../models/order"
import { format } from 'date-fns';

export default function OrderDetails() {

    const { symbol } = useParams();

    const [orders, setOrders] = useState<OrderResult>();
    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const orders = await fetchOrders(symbol);
        setOrders(orders);
    }

    const callCancelOrder = async (id: number) => {
        const confirmed = window.confirm("Cancel Order ?");

        if (confirmed) {
            await cancelOrder(id, symbol);
            await loadData();
        }
    }

    return (
        <div>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Orders / {symbol}</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <button onClick={() => { loadData(); }} className="btn btn-primary btn-sm bg-gradient-success">
                                    Refresh
                                </button>
                            </ol>
                            <ol className="breadcrumb float-sm-right">
                                <Link to="/orders" className="btn btn-primary btn-sm">
                                    Back
                                </Link>
                            </ol>
                        </div>
                    </div>
                    <div>
                        <div className="row">
                            <div className="col-12 col-sm-4">
                                <div className="info-box bg-light">
                                    <div className="info-box-content">
                                        <span className="info-box-text text-center text-muted">Profit</span>
                                        <span className="info-box-number text-center text-muted mb-0">{orders?.profitValue.toFixed(2)} $</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4">
                                <div className="info-box bg-light">
                                    <div className="info-box-content">
                                        <span className="info-box-text text-center text-muted">Orders</span>
                                        <span className="info-box-number text-center text-muted mb-0">{orders?.allOrders.length}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4">
                                <div className="info-box bg-light">
                                    <div className="info-box-content">
                                        <span className="info-box-text text-center text-muted">Total amount</span>
                                        <span className="info-box-number text-center text-muted mb-0">{orders?.allOrders.reduce((v1, v2) => v1 + v2.amount, 0).toFixed(0)} $</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content">

                <div className="card">
                    <div className="card-body p-0">
                        <table className="table table-striped projects">
                            <thead>
                                <tr>
                                    <th style={{ width: "10%" }}>
                                        Date
                                    </th>
                                    <th style={{ width: "10%" }}>
                                        Price
                                    </th>
                                    <th style={{ width: "10%" }}>
                                        Size
                                    </th>
                                    <th style={{ width: "20%" }}>
                                        Profit
                                    </th>
                                    <th style={{ width: "10%" }} className="text-center">
                                        Entry
                                    </th>
                                    <th style={{ width: "40%" }}>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    orders?.allOrders.map(order => (
                                        <tr>
                                            <td>
                                                { format(order.createTime,"dd/MM HH:mm") }
                                            </td>
                                            <td>
                                                {order.price} $
                                            </td>
                                            <td>
                                                {order.amount} $
                                            </td>
                                            <td className="project_progress">
                                                <div className="progress progress-sm">
                                                    <div className={`progress-bar " role="progressbar ` + (order.profitValue > 0 ? 'bg-green' : 'bg-red')} style={{ width: Math.abs(order.profit) * 100 + "%" }}>
                                                    </div>
                                                </div>
                                                <small>
                                                    {order.profitValue.toFixed(2)} $
                                                </small>
                                                <br />
                                                <small>
                                                    {order.profit.toFixed(2)}
                                                </small>
                                            </td>
                                            <td className="project-state">
                                                {order.averageFillPrice} $
                                            </td>
                                            <td className="project-actions text-right">
                                                <button onClick={() => { callCancelOrder(order.id); }} className="btn btn-danger btn-sm" >
                                                    <i className="fas fa-trash">
                                                    </i>
                                                    Cancel
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

            </section>
        </div>
    );
}