import React from 'react';
import { OrderResult } from '../models/order';

export const fetchOrders = async (symbol?: string): Promise<OrderResult> => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/Trades/get-orders${symbol == null ? '' : '?symbol=' + symbol}`);
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        return await response.json();
    } catch (error) {
        throw new Error('Error fetching data');
    }
};

export const cancelOrder = async (id: number, symbol?: string): Promise<void> => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/Trades/cancel-order?symbol=${symbol}&orderId=${id}`);
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        return await response.json();
    } catch (error) {
        throw new Error('Error fetching data');
    }
};

export const cancelAllOrders = async (symbol?: string): Promise<void> => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/Trades/cancel-all-orders?symbol=${symbol}`);
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        return await response.json();
    } catch (error) {
        throw new Error('Error fetching data');
    }
};
