import { Link } from "react-router-dom";
import BotsPieChart from "../components/bots-pie-chart";


export default function Home() {
    return (
        <div>
            <div className="content-header">

            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-success">
                                <div className="inner">
                                    <h4>20</h4>
                                    <h3>53<sup style={{ fontSize: "20px" }}>$</sup></h3>
                                    <p>Orders</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-stats-bars"></i>
                                </div>
                                <Link to="/orders" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-info">
                                <div className="inner">
                                    <h4>20</h4>
                                    <h3>53<sup style={{ fontSize: "20px" }}>$</sup></h3>
                                    <p>Trades</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-bag"></i>
                                </div>
                                <Link to="/trades" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-warning">
                                <div className="inner">
                                    <h4>75000</h4>
                                    <h3>50000</h3>
                                    <p>Balance</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-person-add"></i>
                                </div>
                                <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-danger">
                                <div className="inner">
                                    <h4>65</h4>
                                    <h3>65</h3>
                                    <p>Empty</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-pie-graph"></i>
                                </div>
                                <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <section className="col-lg-7 connectedSortable">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="ion ion-clipboard mr-1"></i>
                                        Bots
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <BotsPieChart></BotsPieChart>
                                </div>
                                <div className="card-footer clearfix">

                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="ion ion-clipboard mr-1"></i>
                                        To Do List
                                    </h3>

                                    <div className="card-tools">
                                        <ul className="pagination pagination-sm">
                                            <li className="page-item"><a href="#" className="page-link">&laquo;</a></li>
                                            <li className="page-item"><a href="#" className="page-link">1</a></li>
                                            <li className="page-item"><a href="#" className="page-link">2</a></li>
                                            <li className="page-item"><a href="#" className="page-link">3</a></li>
                                            <li className="page-item"><a href="#" className="page-link">&raquo;</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <ul className="todo-list" data-widget="todo-list">
                                        <li>
                                            <span className="handle">
                                                <i className="fas fa-ellipsis-v"></i>
                                                <i className="fas fa-ellipsis-v"></i>
                                            </span>
                                            <div className="icheck-primary d-inline ml-2">
                                                <input type="checkbox" value="" name="todo1" id="todoCheck1" />
                                                <label ></label>
                                            </div>
                                            <span className="text">Design a nice theme</span>
                                            <small className="badge badge-danger"><i className="far fa-clock"></i> 2 mins</small>
                                            <div className="tools">
                                                <i className="fas fa-edit"></i>
                                                <i className="fas fa-trash-o"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <span className="handle">
                                                <i className="fas fa-ellipsis-v"></i>
                                                <i className="fas fa-ellipsis-v"></i>
                                            </span>
                                            <div className="icheck-primary d-inline ml-2">
                                                <input type="checkbox" value="" name="todo2" id="todoCheck2" checked />
                                                <label></label>
                                            </div>
                                            <span className="text">Make the theme responsive</span>
                                            <small className="badge badge-info"><i className="far fa-clock"></i> 4 hours</small>
                                            <div className="tools">
                                                <i className="fas fa-edit"></i>
                                                <i className="fas fa-trash-o"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <span className="handle">
                                                <i className="fas fa-ellipsis-v"></i>
                                                <i className="fas fa-ellipsis-v"></i>
                                            </span>
                                            <div className="icheck-primary d-inline ml-2">
                                                <input type="checkbox" value="" name="todo3" id="todoCheck3" />
                                                <label ></label>
                                            </div>
                                            <span className="text">Let theme shine like a star</span>
                                            <small className="badge badge-warning"><i className="far fa-clock"></i> 1 day</small>
                                            <div className="tools">
                                                <i className="fas fa-edit"></i>
                                                <i className="fas fa-trash-o"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <span className="handle">
                                                <i className="fas fa-ellipsis-v"></i>
                                                <i className="fas fa-ellipsis-v"></i>
                                            </span>
                                            <div className="icheck-primary d-inline ml-2">
                                                <input type="checkbox" value="" name="todo4" id="todoCheck4" />
                                                <label ></label>
                                            </div>
                                            <span className="text">Let theme shine like a star</span>
                                            <small className="badge badge-success"><i className="far fa-clock"></i> 3 days</small>
                                            <div className="tools">
                                                <i className="fas fa-edit"></i>
                                                <i className="fas fa-trash-o"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <span className="handle">
                                                <i className="fas fa-ellipsis-v"></i>
                                                <i className="fas fa-ellipsis-v"></i>
                                            </span>
                                            <div className="icheck-primary d-inline ml-2">
                                                <input type="checkbox" value="" name="todo5" id="todoCheck5" />
                                                <label ></label>
                                            </div>
                                            <span className="text">Check your messages and notifications</span>
                                            <small className="badge badge-primary"><i className="far fa-clock"></i> 1 week</small>
                                            <div className="tools">
                                                <i className="fas fa-edit"></i>
                                                <i className="fas fa-trash-o"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <span className="handle">
                                                <i className="fas fa-ellipsis-v"></i>
                                                <i className="fas fa-ellipsis-v"></i>
                                            </span>
                                            <div className="icheck-primary d-inline ml-2">
                                                <input type="checkbox" value="" name="todo6" id="todoCheck6" />
                                                <label ></label>
                                            </div>
                                            <span className="text">Let theme shine like a star</span>
                                            <small className="badge badge-secondary"><i className="far fa-clock"></i> 1 month</small>
                                            <div className="tools">
                                                <i className="fas fa-edit"></i>
                                                <i className="fas fa-trash-o"></i>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-footer clearfix">
                                    <button type="button" className="btn btn-primary float-right"><i className="fas fa-plus"></i> Add item</button>
                                </div>
                            </div>
                        </section>
                        <section className="col-lg-5 connectedSortable">

                        </section>
                    </div>
                </div>
            </section>
        </div>
    );
}