import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import logo from './logo.svg';
import './assets/css/adminlte.css';
import './assets/css/fontawesome-free/css/all.min.css'
import Layout from './layout/layout';
import Home from './pages/home';
import Trades from './pages/trades';
import Orders from './pages/orders';
import Accounts from './pages/accounts';
import OrderDetails from './pages/order-details';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />}></Route>
          <Route path='/trades' element={<Trades />}></Route>
          <Route path='/orders' element={<Orders />}></Route>
          <Route path='/order-details/:symbol' element={<OrderDetails />}></Route>
          <Route path='/accounts' element={<Accounts />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
